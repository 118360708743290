import React from 'react';
import { IconProps } from '.';

const SVG = (props: IconProps) => (
  <svg
    style={props.style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${props.className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 25 25"
    fill="none"
  >
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="10" y="10" width="12" height="12">
      <path d="M10.5877 10.8538L21.7109 10.8538L21.7109 21.9771L10.5877 21.977L10.5877 10.8538Z" fill="white" />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M11.626 21.9773C11.626 16.4077 16.1411 11.8926 21.7107 11.8926C27.2803 11.8926 31.7954 16.4077 31.7954 21.9773C31.7954 27.5469 27.2803 32.062 21.7107 32.062C16.1411 32.062 11.626 27.5469 11.626 21.9773Z"
        stroke="currentColor"
        strokeWidth="2.07721"
      />
      <path
        opacity="0.7"
        d="M16.0764 21.9774C16.0764 18.8651 18.5995 16.342 21.7118 16.342C24.8241 16.342 27.3471 18.8651 27.3471 21.9774C27.3471 25.0897 24.8241 27.6128 21.7118 27.6128C18.5995 27.6128 16.0764 25.0897 16.0764 21.9774Z"
        stroke="currentColor"
        strokeWidth="2.07721"
      />
      <path
        d="M20.5248 21.9775C20.5248 21.3225 21.0559 20.7914 21.7109 20.7914C22.3659 20.7914 22.8969 21.3225 22.8969 21.9775C22.8969 22.6325 22.3659 23.1635 21.7109 23.1635C21.0559 23.1635 20.5248 22.6325 20.5248 21.9775Z"
        stroke="currentColor"
        strokeWidth="2.07721"
      />
    </g>
    <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="4" width="13" height="12">
      <path d="M15.0364 15.303H3.91309V4.17969H15.0364V15.303Z" fill="white" />
    </mask>
    <g mask="url(#mask1)">
      <circle cx="3.91331" cy="4.17992" r="10.0847" stroke="currentColor" strokeWidth="2.07721" />
      <circle opacity="0.7" cx="3.91322" cy="4.17982" r="5.63536" stroke="currentColor" strokeWidth="2.07721" />
      <circle cx="3.91313" cy="4.18071" r="1.18605" stroke="currentColor" strokeWidth="2.07721" />
    </g>
  </svg>
);

export default SVG;

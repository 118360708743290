import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { IUserResponse } from '../../types';
import { USER_TYPES } from './Types';

interface IUserReducer {
  user?: IUserResponse;
}

const InitialState: IUserReducer = {};

const login = (state: IUserReducer, action: any): IUserReducer => {
  return {
    ...state,
    user: action.payload,
  };
};

const details = (state: IUserReducer, action: any): IUserReducer => {
  return {
    ...state,
    user: action.payload,
  };
};

const update = (state: IUserReducer, action: any): IUserReducer => {
  return {
    ...state,
    user: { ...state.user, ...action.payload },
  };
};

const logout = (state: IUserReducer, data: any): IUserReducer => {
  return {
    ...InitialState,
  };
};

const Reducer = (state = InitialState, action: any) => {
  switch (action.type) {
    case USER_TYPES.LOGIN:
      return login(state, action);
    case USER_TYPES.DETAILS:
      return details(state, action);
    case USER_TYPES.USER_UPDATE:
      return update(state, action);
    case USER_TYPES.LOGOUT:
      return logout(state, action);
    default:
      return state;
  }
};

// persistor for userReducer
const persistConfig = {
  key: 'userReducer',
  storage: storage,
};

export const userReducer = persistReducer(persistConfig, Reducer);

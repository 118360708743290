import React from 'react';
import { IconProps } from '.';

const SVG = (props: IconProps) => (
  <svg
    style={props.style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${props.className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 25 25"
    fill="none"
  >
    <path opacity=".72" d="M21.103 5.212a.95.95 0 100-1.9.95.95 0 000 1.9z" stroke="currentColor" strokeWidth="1.701" />
    <path
      d="M15.478 15.86a7.217 7.217 0 01-.48.446m.48-.447a7.23 7.23 0 00.407-.452m-.407.452l5.75 5.75m0 0c-.205.17-.399.285-.566.36l-5.664-5.663m6.23 5.303c.167-.162.27-.348.331-.528l-5.674-5.674m-.887.9a7.154 7.154 0 01-4.653 1.71 7.186 7.186 0 115.54-2.61"
      stroke="currentColor"
      strokeWidth="1.838"
    />
  </svg>
);

export default SVG;

import React from 'react';
import { IconProps } from '.';

const SVG = (props: IconProps) => (
  <svg
    style={props.style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${props.className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 25 25"
    fill="none"
  >
    <path opacity=".7" d="M4.586 17.067l7.2-9 4.371 5.727-2.571 3.273h-9z" stroke="currentColor" strokeWidth="2" />
    <path d="M18.086 11.34l4.5 5.727h-9l2.571-3.273 1.929-2.454z" stroke="currentColor" strokeWidth="2" />
  </svg>
);

export default SVG;

import React from 'react';
import { IconProps } from '.';

const SVG = (props: IconProps) => (
  <svg
    style={props.style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${props.className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 26 25"
    fill="none"
  >
    <path d="M22.1758 20.8237H5.17578V15.8237V4.82373" stroke="currentColor" strokeWidth="2" />
    <path
      opacity="0.7"
      d="M19.4031 9.5376L13.0826 15.6976L9.27993 11.8421L5.16016 15.6976"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path opacity="0.7" d="M17.2031 7.82812H21.1113V11.7364" stroke="currentColor" strokeWidth="2" />
  </svg>
);

export default SVG;

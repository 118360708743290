import React from 'react';
import { IconProps } from '.';

const SVG = (props: IconProps) => (
  <svg
    style={props.style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${props.className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 26 25"
    fill="none"
  >
    <mask id="a" maskUnits="userSpaceOnUse" x="1" y="0" width="25" height="19">
      <path d="M1.586.863h24v18h-24v-18z" fill="#fff" />
    </mask>
    <g mask="url(#a)">
      <path
        opacity=".7"
        d="M3.586 16.863c0-5.523 4.477-10 10-10s10 4.477 10 10a9.951 9.951 0 01-1.986 5.982H5.572a9.951 9.951 0 01-1.986-5.982z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </g>
    <path
      d="M11.586 17.863a2 2 0 003.635 1.152l4.365-7.152-7.154 4.366c-.512.362-.846.959-.846 1.634z"
      fill="currentColor"
    />
  </svg>
);

export default SVG;

import React from 'react';
import { IconProps } from '.';

const SVG = (props: IconProps) => (
  <svg
    style={props.style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${props.className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 26 25"
    fill="none"
  >
    <path
      opacity="0.7"
      d="M3 12.2603C4.5 12.2603 5.5 14.2603 8.5 14.2603C11.5 14.2603 14.5 11.7603 17.5 11.7603C20.5 11.7603 21.5 13.2603 23 13.2603"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M3 7.26025C4.5 7.26025 5.5 9.26025 8.5 9.26025C11.5 9.26025 14.5 6.76025 17.5 6.76025C20.5 6.76025 21.5 8.26025 23 8.26025"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M3 17.2603C4.5 17.2603 5.5 19.2603 8.5 19.2603C11.5 19.2603 14.5 16.7603 17.5 16.7603C20.5 16.7603 21.5 18.2603 23 18.2603"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

export default SVG;

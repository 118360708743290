import React from 'react';
import { IconProps } from '.';

const SVG = (props: IconProps) => (
  <svg
    style={props.style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${props.className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      d="M4.34 8.972c-1.886-1.926-.796-5.174 1.845-5.595.78-2.593 4.188-3.216 5.827-1.033C13.652.16 17.06.784 17.84 3.377c2.642.42 3.73 3.67 1.844 5.595 1.87 1.908.824 5.165-1.837 5.594a3.327 3.327 0 01-1.193 1.726v2.694c0 .864-.554 1.601-1.326 1.875v2.126a.663.663 0 01-.663.663H9.36a.663.663 0 01-.663-.663V20.86a1.992 1.992 0 01-1.326-1.875v-2.694a3.327 3.327 0 01-1.193-1.726c-2.658-.428-3.71-3.683-1.837-5.594zm13.94.58a.664.664 0 010-1.16c1.51-.837 1.31-3.073-.329-3.626-.02.16-.052.316-.096.47a.663.663 0 01-1.276-.362 1.992 1.992 0 00-1.914-2.533c-1.097 0-1.99.893-1.99 1.99v11.295h1.99c1.436 0 2.511-1.503 1.713-3.06-.166.076-.53.225-.722.225a.663.663 0 01-.182-1.3 2.6 2.6 0 001.322-.889.663.663 0 111.043.82c-.11.14-.23.272-.359.395.247.44.403.902.466 1.363 1.64-.547 1.85-2.787.334-3.628zm-4.278 12.772v-1.349h-3.979v1.349h3.979zm-5.305-5.372v2.034c0 .366.297.663.663.663h5.305a.664.664 0 00.663-.663v-2.034H8.697zm-2.952-7.4c-1.511.838-1.31 3.079.334 3.628.063-.461.219-.922.465-1.362a3.892 3.892 0 01-.454-.524.663.663 0 011.075-.776c.342.473.837.816 1.385.972a.663.663 0 01-.364 1.275 3.89 3.89 0 01-.54-.199c-.793 1.55.27 3.06 1.714 3.06h1.99V4.331c0-1.097-.893-1.99-1.99-1.99A1.992 1.992 0 007.475 4.97a.663.663 0 11-1.256.426 3.296 3.296 0 01-.146-.629c-1.636.552-1.84 2.788-.328 3.626a.664.664 0 010 1.16z"
      fill="currentColor"
    />
    <path
      opacity=".72"
      d="M16.653 8.353a.663.663 0 01-.663-.663c0-.145 0-.18-.47-.407C14.952 7.01 14 6.55 14 5.038a.663.663 0 111.327 0c0 .679.258.803.77 1.05.486.235 1.22.59 1.22 1.602a.663.663 0 01-.664.663zM7.926 6.089c.513-.248.77-.372.77-1.051a.663.663 0 111.327 0c0 1.51-.952 1.97-1.52 2.245-.47.227-.47.262-.47.407a.663.663 0 11-1.326 0c0-1.012.734-1.367 1.22-1.601zM21.313 9.019c0-.367.297-.664.663-.664h.69a.663.663 0 010 1.327h-.69a.663.663 0 01-.663-.663zM21.42 3.517a.663.663 0 11-.905-.97l.504-.47a.663.663 0 01.905.97l-.505.47zM21.42 14.427l.504.47a.663.663 0 01-.905.97l-.504-.47a.663.663 0 01.904-.97zM1.358 8.263h.69a.663.663 0 010 1.326h-.69a.663.663 0 010-1.326zM2.604 14.427a.663.663 0 01.905.97l-.505.47a.663.663 0 11-.904-.97l.504-.47zM2.604 3.518l-.504-.47a.663.663 0 01.904-.97l.505.47a.663.663 0 01-.905.97z"
      fill="currentColor"
    />
  </svg>
);

export default SVG;

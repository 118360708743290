import React from 'react';
import { IconProps } from '.';

const SVG = (props: IconProps) => (
  <svg
    style={props.style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${props.className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      opacity="0.7"
      d="M12.5859 8.65869C14.2428 8.65869 15.5859 7.31555 15.5859 5.65869H19.5859V9.65869C21.2428 9.65869 22.5859 11.0018 22.5859 12.6587C22.5859 14.3155 21.2428 15.6587 19.5859 15.6587V19.6587H15.5859C15.5859 18.0018 14.2428 16.6587 12.5859 16.6587C10.9291 16.6587 9.58594 18.0018 9.58594 19.6587H5.58594V15.6587C3.92908 15.6587 2.58594 14.3155 2.58594 12.6587C2.58594 11.0018 3.92908 9.65869 5.58594 9.65869L5.58594 5.65869H9.58594C9.58594 7.31555 10.9291 8.65869 12.5859 8.65869Z"
      stroke="currentColor"
      strokeWidth="2"
    />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="12" y="1" width="13" height="23">
      <rect x="12.5859" y="1.65869" width="12" height="22" fill="black" />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M12.5859 8.65869C14.2428 8.65869 15.5859 7.31555 15.5859 5.65869H19.5859V9.65869C21.2428 9.65869 22.5859 11.0018 22.5859 12.6587C22.5859 14.3155 21.2428 15.6587 19.5859 15.6587V19.6587H15.5859C15.5859 18.0018 14.2428 16.6587 12.5859 16.6587C10.9291 16.6587 9.58594 18.0018 9.58594 19.6587H5.58594V15.6587C3.92908 15.6587 2.58594 14.3155 2.58594 12.6587C2.58594 11.0018 3.92908 9.65869 5.58594 9.65869L5.58594 5.65869H9.58594C9.58594 7.31555 10.9291 8.65869 12.5859 8.65869Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default SVG;

import React from 'react';
import { IconProps } from '.';

const SVG = (props: IconProps) => (
  <svg
    style={props.style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${props.className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      opacity=".7"
      d="M17 5.168v2a1.5 1.5 0 01-1.5 1.5h-.25c-.69 0-1.25.56-1.25 1.25v.05a1.2 1.2 0 01-1.2 1.2 1.8 1.8 0 00-1.8 1.8v.6a1.6 1.6 0 001.6 1.6h2.9a3 3 0 013 3v2M5.105 9.293c.88 4.453 6.047 7.633 5.53 12.59"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M22 13.168a9 9 0 01-18 0 8.996 8.996 0 019-9c1.282 0 2.499.268 3.6.749a9.002 9.002 0 015.4 8.252z"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

export default SVG;

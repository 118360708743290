import React from 'react';
import { IconProps } from '.';

const SVG = (props: IconProps) => (
  <svg
    style={props.style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${props.className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      opacity="0.7"
      d="M4.58594 10.5562C4.58594 6.13787 8.16766 2.55615 12.5859 2.55615C17.0042 2.55615 20.5859 6.13787 20.5859 10.5562V16.5562V16.8318C20.5859 17.3544 20.3813 17.8563 20.016 18.23L16.3743 21.9544C15.998 22.3392 15.4825 22.5562 14.9443 22.5562H12.5859"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M2.58594 11.5562L4.58594 11.5562V15.5562H2.58594V11.5562ZM20.5859 11.5562H22.5859V15.5562H20.5859V11.5562ZM11.5859 21.5562H13.5859V22.5562H11.5859V21.5562Z"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

export default SVG;

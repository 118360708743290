import React from 'react';
import { IconProps } from '.';

const SVG = (props: IconProps) => (
  <svg
    style={props.style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${props.className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 25 25"
    fill="none"
  >
    <g clipPath="url(#clip0)" fill="currentColor">
      <path d="M4.958 12.873h2.22c.302 0 .594.041.873.117a3.324 3.324 0 012.961-1.821h3.924c1.291 0 2.412.742 2.962 1.82.278-.075.57-.116.872-.116h2.22a3.325 3.325 0 013.322 3.321v3.549a1.995 1.995 0 01-1.993 1.992H3.629a1.995 1.995 0 01-1.992-1.992v-3.549a3.325 3.325 0 013.321-3.321zm13.3 1.618v5.916h4.06a.665.665 0 00.665-.665v-3.548a1.995 1.995 0 00-1.993-1.993h-2.22c-.18 0-.354.025-.52.07.004.073.007.146.007.22zm-9.239 5.916h7.91V14.49a1.995 1.995 0 00-1.993-1.993h-3.924A1.995 1.995 0 009.02 14.49v5.916zm-6.054-.665c0 .367.298.665.664.665h4.062V14.49c0-.074.003-.147.008-.22a1.988 1.988 0 00-.521-.07h-2.22a1.995 1.995 0 00-1.993 1.993v3.549z" />
      <path
        opacity=".72"
        d="M19.88 6.599a2.955 2.955 0 012.952 2.951 2.955 2.955 0 01-2.951 2.952 2.955 2.955 0 01-2.952-2.952A2.955 2.955 0 0119.88 6.6zm0 4.574c.895 0 1.623-.728 1.623-1.623s-.728-1.623-1.622-1.623c-.895 0-1.623.728-1.623 1.623s.728 1.623 1.623 1.623zM12.974 2.934a3.948 3.948 0 013.943 3.943 3.948 3.948 0 01-3.943 3.943A3.948 3.948 0 019.03 6.877a3.948 3.948 0 013.943-3.943zm0 6.557a2.618 2.618 0 002.614-2.614 2.618 2.618 0 00-2.614-2.615 2.618 2.618 0 00-2.615 2.615 2.618 2.618 0 002.615 2.614zM6.068 6.599A2.955 2.955 0 019.02 9.55a2.955 2.955 0 01-2.952 2.952A2.955 2.955 0 013.116 9.55 2.955 2.955 0 016.068 6.6zm0 4.574c.895 0 1.623-.728 1.623-1.623s-.728-1.623-1.623-1.623-1.623.728-1.623 1.623.728 1.623 1.623 1.623z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" transform="matrix(-1 0 0 1 24.975 .333)" d="M0 0h24.001v24.001H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;

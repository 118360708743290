import React from 'react';
import { IconProps } from '.';

const SVG = (props: IconProps) => (
  <svg
    style={props.style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${props.className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 25 26"
    fill="none"
  >
    <path fill="currentColor" d="M4.17188 11.3522L5.58609 9.93799L16.0003 20.3522L14.5861 21.7664L4.17188 11.3522Z" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.5859 11.3521C16.1382 11.3521 16.5859 10.9043 16.5859 10.3521C16.5859 9.79977 16.1382 9.35205 15.5859 9.35205C15.0337 9.35205 14.5859 9.79977 14.5859 10.3521C14.5859 10.9043 15.0337 11.3521 15.5859 11.3521ZM15.5859 13.3521C17.2428 13.3521 18.5859 12.0089 18.5859 10.3521C18.5859 8.6952 17.2428 7.35205 15.5859 7.35205C13.9291 7.35205 12.5859 8.6952 12.5859 10.3521C12.5859 12.0089 13.9291 13.3521 15.5859 13.3521Z"
      clipRule="evenodd"
      opacity="0.72"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.9998 4.52362C11.75 3.77348 12.7674 3.35205 13.8283 3.35205H19.5856C21.2425 3.35205 22.5856 4.6952 22.5856 6.35205V12.1094C22.5856 13.1703 22.1642 14.1877 21.4141 14.9378L13.707 22.6449C12.5354 23.8165 10.6359 23.8165 9.46432 22.6449L3.29274 16.4734C2.12117 15.3018 2.12117 13.4023 3.29274 12.2307L10.9998 4.52362ZM13.8283 5.35205C13.2978 5.35205 12.7891 5.56276 12.4141 5.93784L4.70696 13.6449C4.31643 14.0355 4.31643 14.6686 4.70695 15.0592L10.8785 21.2307C11.2691 21.6213 11.9022 21.6213 12.2927 21.2307L19.9998 13.5236C20.3749 13.1486 20.5856 12.6398 20.5856 12.1094V6.35205C20.5856 5.79977 20.1379 5.35205 19.5856 5.35205H13.8283Z"
      clipRule="evenodd"
    />
  </svg>
);

export default SVG;
